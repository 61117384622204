import Phaser from 'phaser';

import Popup from './Popup';
import TextButton from '../button/TextButton';
import { AugmentItem } from './PopupAugments';
import configs from '../../configs/configs';
import { fontFamilies, fontSizes } from '../../../../utils/styles';
import { formatter } from '../../../../utils/numbers';

const { width, height } = configs;

class PopupConfirmAugment extends Popup {
  augment = null;

  constructor(scene, parentModal, { onConfirm }) {
    super(scene, 'popup-medium', { title: 'Apply this Augment?', ribbon: 'ribbon-extra-no-shadow' });

    this.popup.setVisible(false);
    let hitArea = scene.add.graphics();
    hitArea.fillStyle(0xffffff, 0); // 0 opacity, fully invisible
    hitArea.fillRect(0, 0, 0, 0);
    hitArea
      .setInteractive(
        new Phaser.Geom.Rectangle(
          width / 2 - this.popup.width / 2,
          height / 2 - this.popup.height / 2 + 100,
          this.popup.width,
          this.popup.height
        ),
        Phaser.Geom.Rectangle.Contains
      )
      .on(Phaser.Input.Events.GAMEOBJECT_POINTER_DOWN, () => console.log('yo'));
    this.add(hitArea);

    this.background.setFillStyle(0x260343, 0.8);

    this.glow = scene.add.image(this.popup.x, this.popup.y, 'glow');
    this.add(this.glow);

    this.description = scene.add
      .text(width / 2, height / 2 + 480, '', {
        fontSize: fontSizes.large,
        fontFamily: fontFamilies.bold,
        align: 'center',
      })
      .setOrigin(0.5, 0.5);
    this.add(this.description);

    this.buttonBack = new TextButton(
      scene,
      width / 2 - this.popup.width * 0.23,
      height / 2 + this.popup.height / 2 - 20,
      'button-blue',
      'button-blue-pressed',
      () => {
        this.close();
        parentModal.open();
      },
      'Cancel',
      { sound: 'close', fontSize: '82px' }
    );
    this.buttonConfirm = new TextButton(
      scene,
      width / 2 + this.popup.width * 0.23,
      height / 2 + this.popup.height / 2 - 20,
      'button-green',
      'button-green-pressed',
      () => {
        onConfirm();
        scene.events.emit('s-select-augment', { augment: this.augment });
        this.close();
      },
      'Yes',
      { sound: 'button-1', fontSize: '82px' }
    );
    this.add(this.buttonBack);
    this.add(this.buttonConfirm);
  }

  setAugment(augment) {
    this.augment = augment;

    const desc = augment.description
      .replace('{value}', formatter.format(augment.value))
      .replace('{value2}', formatter.format(augment.value2 || 0));
    this.description.text = desc;

    // augment desc too long
    let firstLineWordCount = desc.split(' ').length;
    while (this.description.width > width * 0.8) {
      firstLineWordCount--;
      const firstLineParts = desc.split(' ').slice(0, firstLineWordCount);
      const secondLineParts = desc.split(' ').slice(firstLineWordCount);
      this.description.text = `${firstLineParts.join(' ')}\n${secondLineParts.join(' ')}`;
    }

    if (this.augmentItem) this.augmentItem.destroy();
    this.augmentItem = new AugmentItem(this.scene, width / 2, height / 2, augment);
    this.add(this.augmentItem);
  }
}

export default PopupConfirmAugment;
