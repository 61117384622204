import Phaser from 'phaser';

import configs from '../configs/configs';
import environments from '../../../utils/environments';

const { GAME_VERSION } = environments;

const loadingIconRatio = 100 / 109;
const loadingIconWidth = 100;
const loadingIconHeight = loadingIconWidth / loadingIconRatio;

const windowLoadingTextY = 0.5 * window.innerHeight + loadingIconHeight + 32;

class LoadingScene extends Phaser.Scene {
  assetLoaded = false;
  userInfoLoaded = false;
  loadingPercent = 0;
  globalEvents = [];

  constructor() {
    super('LoadingScene');
  }

  // listeners for global events here
  addGlobalEventListener(name, fn) {
    this.globalEvents.push(name);
    this.game.events.on(name, fn, this);
  }

  // remove global event listeners when scene destroy
  removeListeners() {
    for (const event of this.globalEvents) {
      this.game.events.removeListener(event);
    }
  }

  shutdown() {
    this.removeListeners();
  }

  preload() {
    this.addGlobalEventListener('g-set-user-completed-tutorial', ({ completed }) => {
      this.game.events.emit('g-hide-bg');
      this.scene.start(completed ? 'MainScene' : 'TutorialScene');
      this.scene.remove('LoadingScene');
    });

    this.addGlobalEventListener('g-user-info-loaded', () => {
      this.userInfoLoaded = true;
    });

    const loadingTextY = (windowLoadingTextY * configs.height) / window.innerHeight;
    const progressY = loadingTextY + 150;

    const progressBox = this.add.graphics();
    progressBox.fillStyle(0xffffff, 1);
    progressBox.fillRect(100, progressY, configs.width - 200, 200);
    const progressBar = this.add.graphics();

    const width = this.cameras.main.width;
    // const height = this.cameras.main.height;
    this.loadingText = this.make.text({
      x: width / 2,
      y: loadingTextY,
      text: 'Loading game assets & profile...',
      style: {
        // font: 'bold 85px WixMadeforDisplay',
        fontSize: '78px',
        fontFamily: "'WixMadeforDisplayBold', sans-serif",
        fill: '#ffffff',
      },
    });
    this.loadingText.setOrigin(0.5, 0);
    this.loadingText.setStroke('#000000', 6);

    const percentText = this.make.text({
      x: width / 2,
      y: progressY + 100,
      text: '0%',
      style: {
        fontSize: '78px',
        fontFamily: "'WixMadeforDisplayBold', sans-serif",
        fill: '#000000',
      },
    });
    percentText.setOrigin(0.5, 0.5);

    this.load.on('progress', (value) => {
      if (value * 100 < this.loadingPercent) return;
      this.loadingPercent = parseInt(value * 100);
      percentText.setText(this.loadingPercent + '%');
      progressBar.clear();
      progressBar.fillStyle(0x1026fc, 0.8);
      progressBar.fillRect(100, progressY, (configs.width - 200) * value, 200);
    });

    this.load.on('complete', () => {
      this.assetLoaded = true;
    });

    // sounds
    this.load.audio('bg', '/audios/bg.mp3');
    this.load.audio('button-1', '/audios/button-1.wav');
    this.load.audio('button-2', '/audios/button-2.wav');
    this.load.audio('buy', '/audios/buy.wav');
    this.load.audio('open', '/audios/open.wav');
    this.load.audio('close', '/audios/close.wav');
    this.load.audio('claim-1', '/audios/claim-1.mp3');
    this.load.audio('claim-2', '/audios/claim-2.mp3');
    this.load.audio('claim-3', '/audios/claim-3.mp3');
    this.load.audio('claim-4', '/audios/claim-4.mp3');
    this.load.audio('coinbag-dropoff', '/audios/coinbag-dropoff.mp3');
    this.load.audio('coinbag-pickup', '/audios/coinbag-pickup.mp3');
    this.load.audio('gangster', '/audios/gangster.wav');
    this.load.audio('house', '/audios/house.wav');
    this.load.audio('minion', '/audios/minion.wav');
    this.load.audio('war', '/audios/war.wav');
    this.load.audio('toggle-1', '/audios/toggle-1.wav');
    this.load.audio('toggle-2', '/audios/toggle-2.wav');
    this.load.audio('spin-sound', '/audios/spin-sound.wav');
    this.load.audio('spin-result-sound', '/audios/spin-result-sound.wav');

    this.load.image('bg', '/images/bg_full.png' + `?v=${GAME_VERSION}`);
    this.load.image('bg-xmas', '/images/bg-xmas.png' + `?v=${GAME_VERSION}`);
    this.load.image('gangster-house-1', '/images/gangster-house-1.png' + `?v=${GAME_VERSION}`);
    this.load.image('gangster-house-2', '/images/gangster-house-2.png' + `?v=${GAME_VERSION}`);
    this.load.image('gangster-house-3', '/images/gangster-house-3.png' + `?v=${GAME_VERSION}`);
    this.load.image('gangster-house-4', '/images/gangster-house-4.png' + `?v=${GAME_VERSION}`);
    this.load.image('gangster-house-5', '/images/gangster-house-5.png' + `?v=${GAME_VERSION}`);
    this.load.image('gangster-house-6', '/images/gangster-house-6.png' + `?v=${GAME_VERSION}`);
    this.load.image('gangster-house-7', '/images/gangster-house-7.png' + `?v=${GAME_VERSION}`);
    this.load.image('gangster-house-8', '/images/gangster-house-8.png' + `?v=${GAME_VERSION}`);
    this.load.image('gangster-house-9', '/images/gangster-house-9.png' + `?v=${GAME_VERSION}`);
    this.load.image('gangster-house-10', '/images/gangster-house-10.png' + `?v=${GAME_VERSION}`);
    this.load.image('gangster-house-11', '/images/gangster-house-11.png' + `?v=${GAME_VERSION}`);
    this.load.image('gangster-house-12', '/images/gangster-house-12.png' + `?v=${GAME_VERSION}`);
    this.load.image('gangster-house-13', '/images/gangster-house-13.png' + `?v=${GAME_VERSION}`);
    this.load.image('gangster-house-14', '/images/gangster-house-14.png' + `?v=${GAME_VERSION}`);
    this.load.image('gangster-house-15', '/images/gangster-house-15.png' + `?v=${GAME_VERSION}`);
    this.load.image('gangster-house-sign', '/images/gangster-house-sign.png' + `?v=${GAME_VERSION}`);
    this.load.image('gangster-house-1-xmas', '/images/gangster-house-1-xmas.png' + `?v=${GAME_VERSION}`);
    this.load.image('gangster-house-2-xmas', '/images/gangster-house-2-xmas.png' + `?v=${GAME_VERSION}`);
    this.load.image('gangster-house-3-xmas', '/images/gangster-house-3-xmas.png' + `?v=${GAME_VERSION}`);
    this.load.image('gangster-house-4-xmas', '/images/gangster-house-4-xmas.png' + `?v=${GAME_VERSION}`);
    this.load.image('gangster-house-5-xmas', '/images/gangster-house-5-xmas.png' + `?v=${GAME_VERSION}`);
    this.load.image('gangster-house-6-xmas', '/images/gangster-house-6-xmas.png' + `?v=${GAME_VERSION}`);
    this.load.image('gangster-house-7-xmas', '/images/gangster-house-7-xmas.png' + `?v=${GAME_VERSION}`);
    this.load.image('gangster-house-8-xmas', '/images/gangster-house-8-xmas.png' + `?v=${GAME_VERSION}`);
    this.load.image('gangster-house-9-xmas', '/images/gangster-house-9-xmas.png' + `?v=${GAME_VERSION}`);
    this.load.image('gangster-house-10-xmas', '/images/gangster-house-10-xmas.png' + `?v=${GAME_VERSION}`);
    this.load.image('gangster-house-11-xmas', '/images/gangster-house-11-xmas.png' + `?v=${GAME_VERSION}`);
    this.load.image('gangster-house-12-xmas', '/images/gangster-house-12-xmas.png' + `?v=${GAME_VERSION}`);
    this.load.image('gangster-house-13-xmas', '/images/gangster-house-13-xmas.png' + `?v=${GAME_VERSION}`);
    this.load.image('gangster-house-14-xmas', '/images/gangster-house-14-xmas.png' + `?v=${GAME_VERSION}`);
    this.load.image('gangster-house-15-xmas', '/images/gangster-house-15-xmas.png' + `?v=${GAME_VERSION}`);
    this.load.image('gangster-house-sign-xmas', '/images/gangster-house-sign-xmas.png' + `?v=${GAME_VERSION}`);
    this.load.image('tree-xmas', '/images/tree-xmas.png' + `?v=${GAME_VERSION}`);

    this.load.image('xtoken-balance', '/images/xtoken-balance.png' + `?v=${GAME_VERSION}`);
    this.load.image('token-balance', '/images/token-balance.png' + `?v=${GAME_VERSION}`);
    this.load.image('eth-balance', '/images/eth-balance.png' + `?v=${GAME_VERSION}`);
    this.load.image('poor-balance', '/images/poor-balance.png' + `?v=${GAME_VERSION}`);

    this.load.image('popup', '/images/popup.png' + `?v=${GAME_VERSION}`);
    this.load.image('popup-rewards', '/images/popup-rewards.png' + `?v=${GAME_VERSION}`);
    this.load.image('popup-airdrop', '/images/popup-airdrop.png' + `?v=${GAME_VERSION}`);
    this.load.image('popup-gangwar-requirement', '/images/popup-gangwar-requirement.png' + `?v=${GAME_VERSION}`);
    this.load.image('popup-link-x', '/images/popup-link-x.png' + `?v=${GAME_VERSION}`);
    this.load.image('popup-tiny', '/images/popup-tiny.png' + `?v=${GAME_VERSION}`);
    this.load.image('popup-mini', '/images/popup-mini.png' + `?v=${GAME_VERSION}`);
    this.load.image('popup-small', '/images/popup-small.png' + `?v=${GAME_VERSION}`);
    this.load.image('popup-medium', '/images/popup-medium.png' + `?v=${GAME_VERSION}`);
    this.load.image('popup-large', '/images/popup-large.png' + `?v=${GAME_VERSION}`);
    this.load.image('popup-extra-large', '/images/popup-extra-large.png' + `?v=${GAME_VERSION}`);
    this.load.image('popup-title', '/images/popup-title.png' + `?v=${GAME_VERSION}`);
    this.load.image('popup-title-large', '/images/popup-title-large.png' + `?v=${GAME_VERSION}`);
    this.load.image('popup-welcome-war', '/images/popup-welcome-war.png' + `?v=${GAME_VERSION}`);
    this.load.image('popup-welcome-nowar', '/images/popup-welcome-nowar.png' + `?v=${GAME_VERSION}`);
    this.load.image('popup-welcome-endgame', '/images/popup-welcome-endgame.png' + `?v=${GAME_VERSION}`);

    this.load.image('popup-safehouse-upgrade', '/images/popup-safehouse-upgrade.png' + `?v=${GAME_VERSION}`);
    this.load.image('popup-buy-goon', '/images/popup-buy-goon.png' + `?v=${GAME_VERSION}`);
    this.load.image('popup-buy-thug', '/images/popup-buy-thug.png' + `?v=${GAME_VERSION}`);
    this.load.image('popup-buy-gangster', '/images/popup-buy-gangster.png' + `?v=${GAME_VERSION}`);
    this.load.image('popup-portfolio', '/images/popup-portfolio.png' + `?v=${GAME_VERSION}`);
    this.load.image('popup-statistic', '/images/popup-statistic.png' + `?v=${GAME_VERSION}`);
    this.load.image('popup-referral', '/images/popup-referral.png' + `?v=${GAME_VERSION}`);
    this.load.image('popup-user-details', '/images/popup-user-details.png' + `?v=${GAME_VERSION}`);
    this.load.image('popup-war-machines', '/images/popup-war-machine.png' + `?v=${GAME_VERSION}`);
    this.load.image('popup-war-explain', '/images/popup-war-explain.png' + `?v=${GAME_VERSION}`);
    this.load.image('popup-war-attack', '/images/popup-war-attack.png' + `?v=${GAME_VERSION}`);
    this.load.image('popup-war-history', '/images/popup-war-history.png' + `?v=${GAME_VERSION}`);
    this.load.image('popup-war-upgrades', '/images/popup-war-upgrades.png' + `?v=${GAME_VERSION}`);
    this.load.image('popup-spin', '/images/popup-spin.png' + `?v=${GAME_VERSION}`);
    this.load.image('popup-settings', '/images/popup-settings.png' + `?v=${GAME_VERSION}`);
    this.load.image('popup-spin-history', '/images/popup-spin-history.png' + `?v=${GAME_VERSION}`);
    this.load.image('popup-starter-pack', '/images/popup-starter-pack.png' + `?v=${GAME_VERSION}`);

    this.load.image('ribbon-extra', '/images/ribbon-extra.png' + `?v=${GAME_VERSION}`);
    this.load.image('ribbon-extra-no-shadow', '/images/ribbon-extra-no-shadow.png' + `?v=${GAME_VERSION}`);
    this.load.image('ribbon-welcome', '/images/ribbon-welcome.png' + `?v=${GAME_VERSION}`);
    this.load.image('quantity-plane', '/images/quantity-plane.png' + `?v=${GAME_VERSION}`);

    this.load.image('glow', '/images/glow.png' + `?v=${GAME_VERSION}`);
    this.load.image('guard', '/images/guard.png' + `?v=${GAME_VERSION}`);
    this.load.image('mini-gangster-2', '/images/mini-gangster-2.png' + `?v=${GAME_VERSION}`);
    this.load.image('percent', '/images/percent.png' + `?v=${GAME_VERSION}`);
    this.load.image('gun', '/images/gun.png' + `?v=${GAME_VERSION}`);
    this.load.image('man', '/images/man.png' + `?v=${GAME_VERSION}`);
    this.load.image('eth-coin', '/images/eth-coin.png' + `?v=${GAME_VERSION}`);
    this.load.image('flare', '/images/flare.png' + `?v=${GAME_VERSION}`);
    this.load.image('counter', '/images/counter.png' + `?v=${GAME_VERSION}`);
    this.load.image('dropdown-select', '/images/dropdown-select.png' + `?v=${GAME_VERSION}`);
    this.load.image('dropdown-select-short', '/images/dropdown-select-short.png' + `?v=${GAME_VERSION}`);
    this.load.image('dropdown-options-container', '/images/dropdown-options-container.png' + `?v=${GAME_VERSION}`);
    this.load.image(
      'dropdown-options-container-long',
      '/images/dropdown-options-container-long.png' + `?v=${GAME_VERSION}`
    );
    this.load.image('dropdown-option-bg', '/images/dropdown-option-bg.png' + `?v=${GAME_VERSION}`);
    this.load.image('row-container', '/images/row-container.png' + `?v=${GAME_VERSION}`);
    this.load.image('row-container-93', '/images/row-container-93.png' + `?v=${GAME_VERSION}`);
    this.load.image('row-container-99', '/images/row-container-99.png' + `?v=${GAME_VERSION}`);
    this.load.image('row-container-105', '/images/row-container-105.png' + `?v=${GAME_VERSION}`);
    this.load.image('row-container-119', '/images/row-container-119.png' + `?v=${GAME_VERSION}`);
    this.load.image('row-container-162', '/images/row-container-162.png' + `?v=${GAME_VERSION}`);
    this.load.image('container-airdrop', '/images/container-airdrop.png' + `?v=${GAME_VERSION}`);
    this.load.image('safehouse-mini', '/images/safehouse-mini.png' + `?v=${GAME_VERSION}`);
    this.load.image('swap-arrow', '/images/swap-arrow.png' + `?v=${GAME_VERSION}`);
    this.load.image('arrow-spin-down', '/images/arrow-spin-down.png' + `?v=${GAME_VERSION}`);
    this.load.image('arrow-spin-up', '/images/arrow-spin-up.png' + `?v=${GAME_VERSION}`);
    this.load.image('spin-house', '/images/spin-house.png' + `?v=${GAME_VERSION}`);
    this.load.image('spin-point', '/images/spin-point.png' + `?v=${GAME_VERSION}`);
    this.load.image('spin-reward-house', '/images/spin-reward-house.png' + `?v=${GAME_VERSION}`);
    this.load.image('spin-reward-point', '/images/spin-reward-point.png' + `?v=${GAME_VERSION}`);
    this.load.image('spin-reward-glow-fx', '/images/spin-reward-glow-fx.png' + `?v=${GAME_VERSION}`);
    this.load.image('gang-coin', '/images/gang-coin.png' + `?v=${GAME_VERSION}`);
    this.load.image('gang-coin-small', '/images/gang-coin-small.png' + `?v=${GAME_VERSION}`);
    this.load.image('gold-frame', '/images/gold-frame.png' + `?v=${GAME_VERSION}`);
    this.load.image('augment-container-common', '/images/augment-container-common.png' + `?v=${GAME_VERSION}`);
    this.load.image('augment-container-rare', '/images/augment-container-rare.png' + `?v=${GAME_VERSION}`);
    this.load.image('augment-container-epic', '/images/augment-container-epic.png' + `?v=${GAME_VERSION}`);
    this.load.image('augment-container-legendary', '/images/augment-container-legendary.png' + `?v=${GAME_VERSION}`);
    this.load.image('tooltip-common', '/images/tooltip-common.png' + `?v=${GAME_VERSION}`);
    this.load.image('tooltip-rare', '/images/tooltip-rare.png' + `?v=${GAME_VERSION}`);
    this.load.image('tooltip-epic', '/images/tooltip-epic.png' + `?v=${GAME_VERSION}`);
    this.load.image('tooltip-legendary', '/images/tooltip-legendary.png' + `?v=${GAME_VERSION}`);
    this.load.image('spin-container-1', '/images/spin-container-1.png' + `?v=${GAME_VERSION}`);
    this.load.image('spin-container-2', '/images/spin-container-2.png' + `?v=${GAME_VERSION}`);
    this.load.image('spin-container-3', '/images/spin-container-3.png' + `?v=${GAME_VERSION}`);
    this.load.image('spin-container-4', '/images/spin-container-4.png' + `?v=${GAME_VERSION}`);
    this.load.image('spin-container-5', '/images/spin-container-5.png' + `?v=${GAME_VERSION}`);
    this.load.image('spin-reward-token-1', '/images/spin-reward-token-1.png' + `?v=${GAME_VERSION}`);
    this.load.image('spin-reward-token-2', '/images/spin-reward-token-2.png' + `?v=${GAME_VERSION}`);
    this.load.image('spin-reward-token-3', '/images/spin-reward-token-3.png' + `?v=${GAME_VERSION}`);
    this.load.image('spin-reward-token-4', '/images/spin-reward-token-4.png' + `?v=${GAME_VERSION}`);
    this.load.image('spin-reward-token-5', '/images/spin-reward-token-5.png' + `?v=${GAME_VERSION}`);
    this.load.image('spin-reward-token-6', '/images/spin-reward-token-6.png' + `?v=${GAME_VERSION}`);
    this.load.image('spin-reward-house-1', '/images/spin-reward-house-1.png' + `?v=${GAME_VERSION}`);
    this.load.image('spin-reward-house-2', '/images/spin-reward-house-2.png' + `?v=${GAME_VERSION}`);
    this.load.image('spin-reward-house-3', '/images/spin-reward-house-3.png' + `?v=${GAME_VERSION}`);
    this.load.image('spin-reward-house-4', '/images/spin-reward-house-4.png' + `?v=${GAME_VERSION}`);
    this.load.image('spin-reward-house-5', '/images/spin-reward-house-5.png' + `?v=${GAME_VERSION}`);
    this.load.image('spin-reward-goon-1', '/images/spin-reward-goon-1.png' + `?v=${GAME_VERSION}`);
    this.load.image('spin-reward-goon-2', '/images/spin-reward-goon-2.png' + `?v=${GAME_VERSION}`);
    this.load.image('spin-reward-goon-3', '/images/spin-reward-goon-3.png' + `?v=${GAME_VERSION}`);
    this.load.image('spin-reward-goon-4', '/images/spin-reward-goon-4.png' + `?v=${GAME_VERSION}`);
    this.load.image('spin-reward-goon-5', '/images/spin-reward-goon-5.png' + `?v=${GAME_VERSION}`);
    this.load.image('spin-reward-pistol-1', '/images/spin-reward-pistol-1.png' + `?v=${GAME_VERSION}`);
    this.load.image('spin-reward-pistol-2', '/images/spin-reward-pistol-2.png' + `?v=${GAME_VERSION}`);
    this.load.image('spin-reward-pistol-3', '/images/spin-reward-pistol-3.png' + `?v=${GAME_VERSION}`);
    this.load.image('spin-reward-pistol-4', '/images/spin-reward-pistol-4.png' + `?v=${GAME_VERSION}`);
    this.load.image('spin-reward-pistol-5', '/images/spin-reward-pistol-5.png' + `?v=${GAME_VERSION}`);
    this.load.image('spin-reward-shield-1', '/images/spin-reward-shield-1.png' + `?v=${GAME_VERSION}`);
    this.load.image('spin-reward-shield-2', '/images/spin-reward-shield-2.png' + `?v=${GAME_VERSION}`);
    this.load.image('spin-reward-shield-3', '/images/spin-reward-shield-3.png' + `?v=${GAME_VERSION}`);
    this.load.image('spin-reward-shield-4', '/images/spin-reward-shield-4.png' + `?v=${GAME_VERSION}`);
    this.load.image('spin-reward-shield-5', '/images/spin-reward-shield-5.png' + `?v=${GAME_VERSION}`);
    this.load.image('thug-airdrop-1', '/images/thug-airdrop-1.png' + `?v=${GAME_VERSION}`);
    this.load.image('thug-airdrop-2', '/images/thug-airdrop-2.png' + `?v=${GAME_VERSION}`);
    this.load.image('thug-airdrop-3', '/images/thug-airdrop-3.png' + `?v=${GAME_VERSION}`);
    this.load.image('thug-airdrop-4', '/images/thug-airdrop-4.png' + `?v=${GAME_VERSION}`);
    this.load.image('thug-airdrop-5', '/images/thug-airdrop-5.png' + `?v=${GAME_VERSION}`);
    this.load.image('augment-double-nothing', '/images/augment-double-nothing.png' + `?v=${GAME_VERSION}`);
    this.load.image('augment-risky-investment', '/images/augment-risky-investment.png' + `?v=${GAME_VERSION}`);
    this.load.image('augment-glass-cannon', '/images/augment-glass-cannon.png' + `?v=${GAME_VERSION}`);
    this.load.image('augment-spiky-shields', '/images/augment-spiky-shields.png' + `?v=${GAME_VERSION}`);
    this.load.image('glow-fx', '/images/glow-fx.png' + `?v=${GAME_VERSION}`);
    this.load.image('metamask', '/images/wallet-metamask.png' + `?v=${GAME_VERSION}`);
    this.load.image('unknown', '/images/unknown.png' + `?v=${GAME_VERSION}`);

    this.load.image('button-link-wallet', '/images/button-link-wallet.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-link-wallet-pressed', '/images/button-link-wallet-pressed.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-link-wallet-disabled', '/images/button-link-wallet-disabled.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-airdrop', '/images/button-airdrop.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-airdrop-pressed', '/images/button-airdrop-pressed.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-airdrop-disabled', '/images/button-airdrop-disabled.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-augments', '/images/button-augments.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-augments-pressed', '/images/button-augments-pressed.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-augments-disabled', '/images/button-augments-disabled.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-share-x', '/images/button-share-x.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-share-x-pressed', '/images/button-share-x-pressed.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-claim-thug', '/images/button-claim-thug.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-claim-thug-pressed', '/images/button-claim-thug-pressed.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-send-message', '/images/button-send-message.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-send-message-pressed', '/images/button-send-message-pressed.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-add-target', '/images/button-add-target.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-blue', '/images/button-blue.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-blue-pressed', '/images/button-blue-pressed.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-disabled', '/images/button-disabled.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-blue-med-short', '/images/button-blue-med-short.png' + `?v=${GAME_VERSION}`);
    this.load.image(
      'button-blue-med-short-pressed',
      '/images/button-blue-med-short-pressed.png' + `?v=${GAME_VERSION}`
    );
    this.load.image(
      'button-blue-med-short-disabled',
      '/images/button-blue-med-short-disabled.png' + `?v=${GAME_VERSION}`
    );
    this.load.image('button-blue-med', '/images/button-blue-med.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-blue-med-pressed', '/images/button-blue-med-pressed.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-blue-med-long', '/images/button-blue-med-long.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-blue-med-long-pressed', '/images/button-blue-med-long-pressed.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-med-long-disabled', '/images/button-med-long-disabled.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-red-med', '/images/button-red-med.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-red-med-pressed', '/images/button-red-med-pressed.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-blue-long', '/images/button-blue-long.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-blue-long-pressed', '/images/button-blue-long-pressed.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-blue-long-thick', '/images/button-blue-long-thick.png' + `?v=${GAME_VERSION}`);
    this.load.image(
      'button-blue-long-thick-pressed',
      '/images/button-blue-long-thick-pressed.png' + `?v=${GAME_VERSION}`
    );
    this.load.image('button-blue-short', '/images/button-blue-short.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-blue-short-pressed', '/images/button-blue-short-pressed.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-blue-small-long', '/images/button-blue-small-long.png' + `?v=${GAME_VERSION}`);
    this.load.image(
      'button-blue-small-long-pressed',
      '/images/button-blue-small-long-pressed.png' + `?v=${GAME_VERSION}`
    );
    this.load.image('button-green-long', '/images/button-green-long.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-green-long-pressed', '/images/button-green-long-pressed.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-long-disabled', '/images/button-long-disabled.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-check', '/images/button-check.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-check-pressed', '/images/button-check-pressed.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-close', '/images/button-close.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-close-pressed', '/images/button-close-pressed.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-copy', '/images/button-copy.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-copy-pressed', '/images/button-copy-pressed.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-copy-small', '/images/button-copy-small.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-copy-small-pressed', '/images/button-copy-small-pressed.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-delete', '/images/button-delete.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-deposit', '/images/button-deposit.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-deposit-pressed', '/images/button-deposit-pressed.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-deposit-disabled', '/images/button-deposit-disabled.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-hold', '/images/button-hold.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-hold-pressed', '/images/button-hold-pressed.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-hold-disabled', '/images/button-hold-disabled.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-info', '/images/button-info.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-info-pressed', '/images/button-info-pressed.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-buy', '/images/button-buy.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-buy-pressed', '/images/button-buy-pressed.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-war', '/images/button-war.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-war-pressed', '/images/button-war-pressed.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-max', '/images/button-max.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-max-pressed', '/images/button-max-pressed.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-paste', '/images/button-paste.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-paste-pressed', '/images/button-paste-pressed.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-paste-small', '/images/button-paste-small.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-paste-small-pressed', '/images/button-paste-small-pressed.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-portfolio', '/images/button-portfolio.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-portfolio-pressed', '/images/button-portfolio-pressed.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-rank', '/images/button-rank.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-rank-pressed', '/images/button-rank-pressed.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-referral', '/images/button-referral.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-referral-pressed', '/images/button-referral-pressed.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-referral-disabled', '/images/button-referral-disabled.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-refresh', '/images/button-refresh.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-refresh-pressed', '/images/button-refresh-pressed.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-setting', '/images/button-setting.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-setting-pressed', '/images/button-setting-pressed.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-twitter', '/images/button-twitter.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-twitter-pressed', '/images/button-twitter-pressed.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-twitter-disabled', '/images/button-twitter-disabled.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-add', '/images/button-add.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-add-pressed', '/images/button-add-pressed.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-add-mini', '/images/button-add-mini.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-add-mini-pressed', '/images/button-add-mini-pressed.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-claim', '/images/button-claim.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-claim-pressed', '/images/button-claim-pressed.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-back-portfolio', '/images/button-back-portfolio.png' + `?v=${GAME_VERSION}`);
    this.load.image(
      'button-back-portfolio-pressed',
      '/images/button-back-portfolio-pressed.png' + `?v=${GAME_VERSION}`
    );
    this.load.image('button-discord', '/images/button-discord.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-discord-pressed', '/images/button-discord-pressed.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-square', '/images/button-square.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-square-pressed', '/images/button-square-pressed.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-square-disabled', '/images/button-square-disabled.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-square-normal', '/images/button-square-normal.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-square-normal-pressed', '/images/button-square-normal-pressed.png' + `?v=${GAME_VERSION}`);
    this.load.image(
      'button-square-normal-disabled',
      '/images/button-square-normal-disabled.png' + `?v=${GAME_VERSION}`
    );
    this.load.image('button-green', '/images/button-green.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-green-pressed', '/images/button-green-pressed.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-square-small', '/images/button-square-small.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-square-small-pressed', '/images/button-square-small-pressed.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-square-tiny', '/images/button-square-tiny.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-square-tiny-pressed', '/images/button-square-tiny-pressed.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-spin', '/images/button-spin.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-spin-pressed', '/images/button-spin-pressed.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-spin-claim', '/images/button-spin-claim.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-spin-claim-pressed', '/images/button-spin-claim-pressed.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-spin-disabled', '/images/button-spin-disabled.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-starter-pack', '/images/button-starter-pack.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-starter-pack-pressed', '/images/button-starter-pack-pressed.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-starter-pack-disabled', '/images/button-starter-pack-disabled.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-sort-asc', '/images/button-sort-asc.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-sort-desc', '/images/button-sort-desc.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-raid', '/images/button-raid.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-raid-pressed', '/images/button-raid-pressed.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-reward', '/images/button-reward.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-reward-pressed', '/images/button-reward-pressed.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-daily-spin', '/images/button-daily-spin.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-daily-spin-pressed', '/images/button-daily-spin-pressed.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-daily-spin-disabled', '/images/button-daily-spin-disabled.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-green-full-length', '/images/button-green-full-length.png' + `?v=${GAME_VERSION}`);
    this.load.image(
      'button-green-full-length-pressed',
      '/images/button-green-full-length-pressed.png' + `?v=${GAME_VERSION}`
    );
    this.load.image(
      'button-green-full-length-disabled',
      '/images/button-green-full-length-disabled.png' + `?v=${GAME_VERSION}`
    );
    this.load.image('button-buy-safehouse', '/images/button-buy-safehouse.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-buy-safehouse-pressed', '/images/button-buy-safehouse-pressed.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-buy-thug', '/images/button-buy-thug.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-buy-thug-pressed', '/images/button-buy-thug-pressed.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-buy-gangster', '/images/button-buy-gangster.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-buy-gangster-pressed', '/images/button-buy-gangster-pressed.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-buy-goon', '/images/button-buy-goon.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-buy-goon-pressed', '/images/button-buy-goon-pressed.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-link-twitter', '/images/button-link-twitter.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-link-twitter-pressed', '/images/button-link-twitter-pressed.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-unlink-twitter', '/images/button-unlink-twitter.png' + `?v=${GAME_VERSION}`);
    this.load.image(
      'button-unlink-twitter-pressed',
      '/images/button-unlink-twitter-pressed.png' + `?v=${GAME_VERSION}`
    );
    this.load.image('button-share-on-x', '/images/button-share-on-x.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-share-on-x-pressed', '/images/button-share-on-x-pressed.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-wiki', '/images/button-wiki.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-wiki-pressed', '/images/button-wiki-pressed.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-x', '/images/button-x.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-x-pressed', '/images/button-x-pressed.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-playtest-claim', '/images/button-playtest-claim.png' + `?v=${GAME_VERSION}`);
    this.load.image(
      'button-playtest-claim-pressed',
      '/images/button-playtest-claim-pressed.png' + `?v=${GAME_VERSION}`
    );
    this.load.image(
      'button-playtest-claim-disabled',
      '/images/button-playtest-claim-disabled.png' + `?v=${GAME_VERSION}`
    );
    this.load.image('button-link-x', '/images/button-link-x.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-link-x-pressed', '/images/button-link-x-pressed.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-auction-house', '/images/button-auction-house.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-auction-house-pressed', '/images/button-auction-house-pressed.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-bid', '/images/button-bid.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-bid-pressed', '/images/button-bid-pressed.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-rewards', '/images/button-rewards.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-rewards-pressed', '/images/button-rewards-pressed.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-rewards-disabled', '/images/button-rewards-disabled.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-reward-share', '/images/button-reward-share.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-reward-share-pressed', '/images/button-reward-share-pressed.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-reward-share-disabled', '/images/button-reward-share-disabled.png' + `?v=${GAME_VERSION}`);
    this.load.image('btn-follow', '/images/btn-follow.png' + `?v=${GAME_VERSION}`);
    this.load.image('btn-follow-pressed', '/images/btn-follow-pressed.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-auction-mode', '/images/button-auction-mode.png' + `?v=${GAME_VERSION}`);

    this.load.image('auction-mode-container', '/images/auction-mode-container.png' + `?v=${GAME_VERSION}`);
    this.load.image('countdown-container', '/images/countdown-container.png' + `?v=${GAME_VERSION}`);
    this.load.image('menu-long', '/images/menu-long.png' + `?v=${GAME_VERSION}`);
    this.load.image('menu-super-long', '/images/menu-super-long.png' + `?v=${GAME_VERSION}`);
    this.load.image('next-claim-container', '/images/next-claim-container.png' + `?v=${GAME_VERSION}`);
    this.load.image('auction-bg', '/images/auction-bg.png' + `?v=${GAME_VERSION}`);
    this.load.image('auction-house', '/images/auction-house.png' + `?v=${GAME_VERSION}`);
    this.load.image('auction-item-container', '/images/auction-item-container.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-auction-back', '/images/button-auction-back.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-auction-back-pressed', '/images/button-auction-back-pressed.png' + `?v=${GAME_VERSION}`);
    this.load.image('icon-visa', '/images/icons/visa.png' + `?v=${GAME_VERSION}`);
    this.load.image('icon-mastercard', '/images/icons/mastercard.png' + `?v=${GAME_VERSION}`);
    this.load.image('icon-paypal', '/images/icons/paypal.png' + `?v=${GAME_VERSION}`);
    this.load.image('icon-google-pay', '/images/icons/google-pay.png' + `?v=${GAME_VERSION}`);
    this.load.image('icon-apple-pay', '/images/icons/apple-pay.png' + `?v=${GAME_VERSION}`);
    this.load.image('icon-goon-bid-small', '/images/icons/goon-bid-small.png' + `?v=${GAME_VERSION}`);
    this.load.image('icon-gangster-bid-small', '/images/icons/gangster-bid-small.png' + `?v=${GAME_VERSION}`);
    this.load.image('goon-tiny', '/images/icons/goon-tiny.png' + `?v=${GAME_VERSION}`);
    this.load.image('gangster-tiny', '/images/icons/gangster-tiny.png' + `?v=${GAME_VERSION}`);
    this.load.image('blast-gold-tiny', '/images/icons/blast-gold-tiny.png' + `?v=${GAME_VERSION}`);
    this.load.image('goon-prize-1', '/images/goon-prize-1.png' + `?v=${GAME_VERSION}`);
    this.load.image('gangster-prize-1', '/images/gangster-prize-1.png' + `?v=${GAME_VERSION}`);
    this.load.image('gangster-prize-2', '/images/gangster-prize-2.png' + `?v=${GAME_VERSION}`);
    this.load.image('gangster-prize-3', '/images/gangster-prize-3.png' + `?v=${GAME_VERSION}`);
    this.load.image('gangster-prize-4', '/images/gangster-prize-4.png' + `?v=${GAME_VERSION}`);
    this.load.image('gangster-prize-5', '/images/gangster-prize-5.png' + `?v=${GAME_VERSION}`);
    this.load.image('greed-prize-1', '/images/greed-prize-1.png' + `?v=${GAME_VERSION}`);
    this.load.image('greed-prize-2', '/images/greed-prize-2.png' + `?v=${GAME_VERSION}`);
    this.load.image('greed-prize-3', '/images/greed-prize-3.png' + `?v=${GAME_VERSION}`);
    this.load.image('greed-prize-4', '/images/greed-prize-4.png' + `?v=${GAME_VERSION}`);
    this.load.image('xgreed-prize-1', '/images/xgreed-prize-1.png' + `?v=${GAME_VERSION}`);
    this.load.image('xgreed-prize-2', '/images/xgreed-prize-2.png' + `?v=${GAME_VERSION}`);
    this.load.image('xgreed-prize-3', '/images/xgreed-prize-3.png' + `?v=${GAME_VERSION}`);
    this.load.image('xgreed-prize-4', '/images/xgreed-prize-4.png' + `?v=${GAME_VERSION}`);
    this.load.image('auction-item-value-container', '/images/auction-item-value-container.png' + `?v=${GAME_VERSION}`);
    this.load.image('popup-auction-bidding', '/images/popup-auction-bidding.png' + `?v=${GAME_VERSION}`);
    this.load.image('auction-text-box', '/images/auction-text-box.png' + `?v=${GAME_VERSION}`);
    this.load.image('container-bidding-history', '/images/container-bidding-history.png' + `?v=${GAME_VERSION}`);
    this.load.image(
      'container-auction-item-history-detail',
      '/images/container-auction-item-history-detail.png' + `?v=${GAME_VERSION}`
    );
    this.load.image('greed-tiny', '/images/greed-tiny.png' + `?v=${GAME_VERSION}`);
    this.load.image('poor-tiny', '/images/poor-tiny.png' + `?v=${GAME_VERSION}`);
    this.load.image('xgreed-tiny', '/images/xgreed-tiny.png' + `?v=${GAME_VERSION}`);
    this.load.image('search', '/images/search.png' + `?v=${GAME_VERSION}`);
    this.load.image('defense-alert', '/images/defense-alert.png' + `?v=${GAME_VERSION}`);
    this.load.image('defense-lazy', '/images/defense-lazy.png' + `?v=${GAME_VERSION}`);
    this.load.image('blast-gold-prize-1', '/images/blast-gold-prize-1.png' + `?v=${GAME_VERSION}`);
    this.load.image('blast-gold-prize-2', '/images/blast-gold-prize-2.png' + `?v=${GAME_VERSION}`);
    this.load.image('blast-gold-prize-3', '/images/blast-gold-prize-3.png' + `?v=${GAME_VERSION}`);

    this.load.image('text-input', '/images/text-input.png' + `?v=${GAME_VERSION}`);
    this.load.image('text-input-small', '/images/text-input-small.png' + `?v=${GAME_VERSION}`);
    this.load.image('search-input', '/images/search-input.png' + `?v=${GAME_VERSION}`);
    this.load.image('pagination', '/images/pagination.png' + `?v=${GAME_VERSION}`);
    this.load.image('pagination-active', '/images/pagination-active.png' + `?v=${GAME_VERSION}`);
    this.load.image('pagination-disabled', '/images/pagination-disabled.png' + `?v=${GAME_VERSION}`);
    this.load.image('deploy-now', '/images/deploy-now.png' + `?v=${GAME_VERSION}`);
    this.load.image('tooltip-next-war', '/images/tooltip-next-war.png' + `?v=${GAME_VERSION}`);
    this.load.image('tooltip-leaderboard', '/images/tooltip-leaderboard.png' + `?v=${GAME_VERSION}`);
    this.load.image('tooltip-leaderboard-bonus', '/images/tooltip-leaderboard-bonus.png' + `?v=${GAME_VERSION}`);
    this.load.image('badge', '/images/badge.png' + `?v=${GAME_VERSION}`);
    this.load.image('arrow-down-gold', '/images/arrow-down-gold.png' + `?v=${GAME_VERSION}`);
    this.load.image('arrow-up-gold', '/images/arrow-up-gold.png' + `?v=${GAME_VERSION}`);
    this.load.image('x', '/images/x.png' + `?v=${GAME_VERSION}`);
    this.load.image('union-brown', '/images/union-brown.png' + `?v=${GAME_VERSION}`);
    this.load.image('playtest-airdrop', '/images/playtest-airdrop.png' + `?v=${GAME_VERSION}`);
    this.load.image('chat-input', '/images/chat-input.png' + `?v=${GAME_VERSION}`);

    this.load.image('text-container', '/images/text-container.png' + `?v=${GAME_VERSION}`);
    this.load.image('text-container-outlined', '/images/text-container-outlined.png' + `?v=${GAME_VERSION}`);
    this.load.image('tabs-container', '/images/tabs-container.png' + `?v=${GAME_VERSION}`);
    this.load.image('tabs-container-simple', '/images/tabs-container-simple.png' + `?v=${GAME_VERSION}`);
    this.load.image('tabs-button', '/images/tabs-button.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-tab-single', '/images/button-tab-single.png' + `?v=${GAME_VERSION}`);
    this.load.image('button-tab-double', '/images/button-tab-double.png' + `?v=${GAME_VERSION}`);
    this.load.image('container-short', '/images/container-short.png' + `?v=${GAME_VERSION}`);
    this.load.image('container-large', '/images/container-large.png' + `?v=${GAME_VERSION}`);
    this.load.image('container-medium', '/images/container-medium.png' + `?v=${GAME_VERSION}`);
    this.load.image('container-large-2', '/images/container-large-2.png' + `?v=${GAME_VERSION}`);
    this.load.image('container-large-3', '/images/container-large-3.png' + `?v=${GAME_VERSION}`);
    this.load.image('container-small', '/images/container-small.png' + `?v=${GAME_VERSION}`);
    this.load.image('container-super-large', '/images/container-super-large.png' + `?v=${GAME_VERSION}`);
    this.load.image('container-border', '/images/container-border.png' + `?v=${GAME_VERSION}`);
    this.load.image('container-border-top', '/images/container-border-top.png' + `?v=${GAME_VERSION}`);
    this.load.image('container-border-middle', '/images/container-border-middle.png' + `?v=${GAME_VERSION}`);
    this.load.image('container-border-bottom', '/images/container-border-bottom.png' + `?v=${GAME_VERSION}`);
    this.load.image('container-spin-history', '/images/container-spin-history.png' + `?v=${GAME_VERSION}`);
    this.load.image('container-spin-new-item', '/images/container-spin-new-item.png' + `?v=${GAME_VERSION}`);
    this.load.image('timer-container', '/images/timer-container.png' + `?v=${GAME_VERSION}`);
    this.load.image('player-rank-container', '/images/player-rank-container.png' + `?v=${GAME_VERSION}`);
    this.load.image('price-chart-title-container', '/images/price-chart-title-container.png' + `?v=${GAME_VERSION}`);
    this.load.image('price-chart-frame', '/images/price-chart-frame.png' + `?v=${GAME_VERSION}`);
    this.load.image('swap-eth-token', '/images/swap-eth-token.png' + `?v=${GAME_VERSION}`);
    this.load.image('swap-token-eth', '/images/swap-token-eth.png' + `?v=${GAME_VERSION}`);
    this.load.image('swap-xgang-gang', '/images/swap-xgang-gang.png' + `?v=${GAME_VERSION}`);
    let path = this.load.path;
    this.load.path = '/images/animation/';
    this.load.multiatlas('gangster-front', 'gangster_front.json');
    this.load.multiatlas('gangster-back', 'gangster_back.json');
    this.load.multiatlas('goon-front', 'goon_front.json');
    this.load.multiatlas('goon-back', 'goon_back.json');
    this.load.multiatlas('thug-front', 'thug_front.json');
    this.load.multiatlas('thug-back', 'thug_back.json');
    this.load.path = path;
    // settings
    this.load.image('settings-wallet-container', '/images/settings-wallet-container.png' + `?v=${GAME_VERSION}`);
    this.load.image('view-transaction', '/images/texts/view-transaction.png' + `?v=${GAME_VERSION}`);
    this.load.image('view-pistol-transaction', '/images/texts/view-pistol-transaction.png' + `?v=${GAME_VERSION}`);
    this.load.image('view-shield-transaction', '/images/texts/view-shield-transaction.png' + `?v=${GAME_VERSION}`);

    this.load.image('icon-privy', '/images/icons/privy.png' + `?v=${GAME_VERSION}`);
    this.load.image('icon-blast', '/images/icons/blast.png' + `?v=${GAME_VERSION}`);
    this.load.image('icon-buy-starter-pack', '/images/icons/buy-starter-pack.png' + `?v=${GAME_VERSION}`);
    this.load.image('icon-buy-starter-pack-done', '/images/icons/buy-starter-pack-done.png' + `?v=${GAME_VERSION}`);
    this.load.image('icon-blast-gold', '/images/icons/blast-gold.png' + `?v=${GAME_VERSION}`);
    this.load.image('icon-blast-gold-small', '/images/icons/blast-gold-small.png' + `?v=${GAME_VERSION}`);
    this.load.image('icon-chevron-right', '/images/icons/chevron-right.png' + `?v=${GAME_VERSION}`);
    this.load.image('icon-check', '/images/icons/check.png' + `?v=${GAME_VERSION}`);
    this.load.image('icon-checkbox-true', '/images/icons/checkbox-true.png' + `?v=${GAME_VERSION}`);
    this.load.image('icon-checkbox-false', '/images/icons/checkbox-false.png' + `?v=${GAME_VERSION}`);
    this.load.image('icon-clock', '/images/icons/clock.png' + `?v=${GAME_VERSION}`);
    this.load.image('icon-coin-mini', '/images/icons/coin-mini.png' + `?v=${GAME_VERSION}`);
    this.load.image('icon-coin-small', '/images/icons/coin-small.png' + `?v=${GAME_VERSION}`);
    this.load.image('icon-coin-done', '/images/icons/coin-done.png' + `?v=${GAME_VERSION}`);
    this.load.image('icon-defend', '/images/icons/defend.png' + `?v=${GAME_VERSION}`);
    this.load.image('icon-xcoin-glowing', '/images/icons/xcoin-glowing.png' + `?v=${GAME_VERSION}`);
    this.load.image('icon-xcoin-outlined-small', '/images/icons/xcoin-outlined-small.png' + `?v=${GAME_VERSION}`);
    this.load.image('icon-xtoken-tiny', '/images/icons/xtoken-tiny.png' + `?v=${GAME_VERSION}`);
    this.load.image('icon-arrow-dropdown', '/images/icons/arrow-dropdown.png' + `?v=${GAME_VERSION}`);
    this.load.image('icon-eth', '/images/icons/eth.png' + `?v=${GAME_VERSION}`);
    this.load.image('icon-eth-small', '/images/icons/eth-small.png' + `?v=${GAME_VERSION}`);
    this.load.image('icon-eth-tiny', '/images/icons/eth-tiny.png' + `?v=${GAME_VERSION}`);
    this.load.image('icon-eth-done', '/images/icons/eth-done.png' + `?v=${GAME_VERSION}`);
    this.load.image('icon-gangster', '/images/icons/gangster.png' + `?v=${GAME_VERSION}`);
    this.load.image('icon-gangster-medium', '/images/icons/gangster-medium.png' + `?v=${GAME_VERSION}`);
    this.load.image('icon-gangster-small', '/images/icons/gangster-small.png' + `?v=${GAME_VERSION}`);
    this.load.image('icon-gangster-mini', '/images/icons/gangster-mini.png' + `?v=${GAME_VERSION}`);
    this.load.image('icon-gangster-buy-done', '/images/icons/gangster-buy-done.png' + `?v=${GAME_VERSION}`);
    this.load.image('icon-gangster-buy-fail', '/images/icons/gangster-buy-fail.png' + `?v=${GAME_VERSION}`);
    this.load.image('icon-goon-medium', '/images/icons/goon-medium.png' + `?v=${GAME_VERSION}`);
    this.load.image('icon-goon-buy-done', '/images/icons/goon-buy-done.png' + `?v=${GAME_VERSION}`);
    this.load.image('icon-goon-buy-fail', '/images/icons/goon-buy-fail.png' + `?v=${GAME_VERSION}`);
    this.load.image('icon-thug-buy-done', '/images/icons/thug-buy-done.png' + `?v=${GAME_VERSION}`);
    this.load.image('icon-thug-buy-fail', '/images/icons/thug-buy-fail.png' + `?v=${GAME_VERSION}`);
    this.load.image('icon-thug-medium', '/images/icons/thug-medium.png' + `?v=${GAME_VERSION}`);
    this.load.image('icon-poor-small', '/images/icons/poor-small.png' + `?v=${GAME_VERSION}`);
    this.load.image('icon-info', '/images/icons/info.png' + `?v=${GAME_VERSION}`);
    this.load.image('icon-loading', '/images/icons/loading.png' + `?v=${GAME_VERSION}`);
    this.load.image('icon-loading-small', '/images/icons/loading-small.png' + `?v=${GAME_VERSION}`);
    this.load.image('icon-logout', '/images/icons/logout.png' + `?v=${GAME_VERSION}`);
    this.load.image('icon-nft-done', '/images/icons/nft-done.png' + `?v=${GAME_VERSION}`);
    this.load.image('icon-open-link', '/images/icons/open-link.png' + `?v=${GAME_VERSION}`);
    this.load.image('icon-open-link-blue', '/images/icons/open-link-blue.png' + `?v=${GAME_VERSION}`);
    this.load.image('icon-pistol', '/images/icons/pistol.png' + `?v=${GAME_VERSION}`);
    this.load.image('icon-ribbon-chart', '/images/icons/ribbon-chart.png' + `?v=${GAME_VERSION}`);
    this.load.image('icon-safehouse-medium', '/images/icons/safehouse-medium.png' + `?v=${GAME_VERSION}`);
    this.load.image('icon-safehouse-upgrade-done', '/images/icons/safehouse-upgrade-done.png' + `?v=${GAME_VERSION}`);
    this.load.image('icon-safehouse-upgrade-fail', '/images/icons/safehouse-upgrade-fail.png' + `?v=${GAME_VERSION}`);
    this.load.image('icon-search', '/images/icons/search.png' + `?v=${GAME_VERSION}`);
    this.load.image('icon-search-contained', '/images/icons/search-contained.png' + `?v=${GAME_VERSION}`);
    this.load.image('icon-settings', '/images/icons/settings.png' + `?v=${GAME_VERSION}`);
    this.load.image('icon-shield', '/images/icons/shield.png' + `?v=${GAME_VERSION}`);
    this.load.image('icon-sound-on', '/images/icons/sound-on.png' + `?v=${GAME_VERSION}`);
    this.load.image('icon-sound-off', '/images/icons/sound-off.png' + `?v=${GAME_VERSION}`);
    this.load.image('icon-star', '/images/icons/star.png' + `?v=${GAME_VERSION}`);
    this.load.image('icon-star-medium', '/images/icons/star-medium.png' + `?v=${GAME_VERSION}`);
    this.load.image('icon-u-point', '/images/icons/u-point.png' + `?v=${GAME_VERSION}`);
    this.load.image('icon-union', '/images/icons/union.png' + `?v=${GAME_VERSION}`);
    this.load.image('icon-view', '/images/icons/view.png' + `?v=${GAME_VERSION}`);
    this.load.image('icon-wallet', '/images/icons/wallet.png' + `?v=${GAME_VERSION}`);
    this.load.image('icon-war-upgrade', '/images/icons/war-upgrade.png' + `?v=${GAME_VERSION}`);
    this.load.image('icon-war-upgrade-done', '/images/icons/war-upgrade-done.png' + `?v=${GAME_VERSION}`);
    this.load.image('icon-xgang-small', '/images/icons/xgang-small.png' + `?v=${GAME_VERSION}`);
    this.load.image('icon-xgang', '/images/icon-xgang.png' + `?v=${GAME_VERSION}`);
    this.load.image('icon-gangster-large', '/images/gangster-large.png' + `?v=${GAME_VERSION}`);

    this.load.image('icon-upgrades', '/images/icon-upgrades.png' + `?v=${GAME_VERSION}`);
    this.load.image('icon-history', '/images/icon-history.png' + `?v=${GAME_VERSION}`);
    this.load.image('icon-war', '/images/icon-war.png' + `?v=${GAME_VERSION}`);
    this.load.image('icon-error-network', '/images/icons/error-network.png' + `?v=${GAME_VERSION}`);
    this.load.image('icon-error-unknown', '/images/icons/error-unknown.png' + `?v=${GAME_VERSION}`);
    this.load.image('icon-error-insufficient', '/images/icons/error-insufficient.png' + `?v=${GAME_VERSION}`);
    this.load.image('deposit-more-eth', '/images/deposit-more-eth.png' + `?v=${GAME_VERSION}`);
    this.load.image('spin-confirmation', '/images/spin-confirmation.png' + `?v=${GAME_VERSION}`);
    this.load.image('number-of-spin-container', '/images/number-of-spin-container.png' + `?v=${GAME_VERSION}`);
    this.load.image('loading-banner', '/images/loading-banner.png' + `?v=${GAME_VERSION}`);

    this.load.image('tutorial-arrow-up', '/images/tutorial-arrow-up.png' + `?v=${GAME_VERSION}`);
    this.load.image('tutorial-arrow-down', '/images/tutorial-arrow-down.png' + `?v=${GAME_VERSION}`);
    this.load.image('tutorial-arrow-left', '/images/tutorial-arrow-left.png' + `?v=${GAME_VERSION}`);
    this.load.image('tutorial-arrow-right', '/images/tutorial-arrow-right.png' + `?v=${GAME_VERSION}`);
    this.load.image('tutorial-claim-inactive-btn', '/images/tutorial-claim-inactive-btn.png' + `?v=${GAME_VERSION}`);
    this.load.image('tutorial-1', '/images/tutorial-1.png' + `?v=${GAME_VERSION}`);
    this.load.image('tutorial-2', '/images/tutorial-2.png' + `?v=${GAME_VERSION}`);
    this.load.image('tutorial-2-overlay', '/images/tutorial-2-overlay.png' + `?v=${GAME_VERSION}`);
    this.load.image('tutorial-3', '/images/tutorial-3.png' + `?v=${GAME_VERSION}`);
    this.load.image('tutorial-4', '/images/tutorial-4.png' + `?v=${GAME_VERSION}`);
    this.load.image('tutorial-5', '/images/tutorial-5.png' + `?v=${GAME_VERSION}`);
    this.load.image('tutorial-5-claim-btn', '/images/tutorial-5-claim-btn.png' + `?v=${GAME_VERSION}`);
    this.load.image('tutorial-6', '/images/tutorial-6.png' + `?v=${GAME_VERSION}`);
    this.load.image('tutorial-7', '/images/tutorial-7.png' + `?v=${GAME_VERSION}`);
    this.load.image('tutorial-8', '/images/tutorial-8.png' + `?v=${GAME_VERSION}`);
    this.load.image('tutorial-9', '/images/tutorial-9.png' + `?v=${GAME_VERSION}`);
    this.load.image('tutorial-10', '/images/tutorial-10.png' + `?v=${GAME_VERSION}`);
    this.load.image('tutorial-11', '/images/tutorial-11.png' + `?v=${GAME_VERSION}`);
    this.load.image('tutorial-12', '/images/tutorial-12.png' + `?v=${GAME_VERSION}`);
  }

  create() {}

  update() {
    if (this.userInfoLoaded && this.assetLoaded) {
      this.game.events.emit('g-check-user-completed-tutorial');
    } else if (this.assetLoaded) {
      this.game.events.emit('g-check-user-loaded');
      if (this.loadingText) {
        this.loadingText.text = 'Loading profile...';
      }
    } else if (this.userInfoLoaded) {
      if (this.loadingText) {
        this.loadingText.text = 'Loading game assets...';
      }
    }
  }
}

export default LoadingScene;
